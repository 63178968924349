
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 3rem;
  background-color: var(--primary-color);
  color: var(--text-color);

}

.header .logo a {
  color: var(--text-color);
  text-decoration: none;
  font-size: 1.2rem;
  font-weight: bold;
}

.header .nav ul {
  list-style: none;
  display: flex;
  gap: 1rem;
  padding: 0;
  margin: 0;
}

.header .nav ul li a {
  color: var(--text-color);
  text-decoration: none;
  transition: color 0.3s ease-in-out;
  
}

.header .nav ul li a:hover {
  color: var(--accent-color);
}

.header .social-links {
  display: flex;
  gap: 1rem;
  color: var(--text-color);
}

.header .social-links a {
  font-size: 1.2rem;
  font-weight:900;
  transition: color 0.3s ease-in-out;
  color: var(--accent-color);
}

.header .social-links a:hover {
  color: antiquewhite;
}

/* Responsive styling */
@media (max-width: 768px) {
  .header {
    flex-direction: column;
  }
  .header .nav ul {
    flex-direction: column;
    align-items: center;
  }
  .header .social-links {
    justify-content: center;
    margin-top: 1rem;
  }
}
