/* TabNavigation.css */
.parent-container {
    display: flex;
    
    justify-content: center; /* Horizontally center */
    align-items: center; /* Vertically center */
    height: 100%; /* Ensure the parent container has a defined height */
}


.tab-navigation {
    width: auto;
    height: auto;
    border: #1f6feb  solid thin;
    border-radius: 20px;
    padding: 1rem;
    background: #1f6feb; /* Dark background for the tab */
    
    
  }
  
  .tab-navigation a {
    text-decoration: none;
    color: #fff; /* Light color for the text */
    margin: 0 1rem;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .tab-navigation a:hover,
  .tab-navigation .active-tab {
    background-color: #58a6ff; /* Slightly lighter background for active/hover state */
  }
  
  .active-tab {
    background-color: #58a6ff; /* Slightly lighter background for active state */
    color: #fff;
    border-radius: 5px;
  }