.about-me {
    color: #cccccc; /* Slightly dimmed text for contrast */
    padding-left: 12rem;
    padding-right: 12rem;
    margin-top: 1rem;
  }
  p{
    font-size: 18px;
    color: white;
  }
h2{
  font-size: 30px;
  color: white;
}

.profile-image{
  border-radius: 50%;
  max-width: 250px;
  width: auto;
  height: auto;
  object-fit: cover;
}

.main{
  display: flex;
}
.image-container{
  width: 25%;
}

.content{
  width: 75%;
}
