/* src/sections/BlogPage.css */
.publication-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px; /* Adds space between cards */
    padding: 20px;
  }
  
  .project {
    background-color: #1e2125; /* Dark theme card background */
    color: #ffffff; /* Light text color for dark theme */
    border-radius: 8px; /* Rounded corners for the card */
    overflow: hidden; /* Ensures nothing spills outside the card's rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
    transition: transform 0.2s; /* Smooth transition for hover effect */
    width: calc(33.333% - 20px); /* Three cards per row, accounting for gap */
    max-width: 600px; /* Maximum width of card */
    min-width: 400px; /* Minimum width of card */
  }
  
  .project:hover {
    transform: scale(1.03); /* Slight increase in size on hover */
  }
  
  .project-title {
    background-color: var(--accent-color); /* Use your accent color here */
    color: #fff;
    padding: 15px;
    margin: 0;
    font-size: 1.2rem;
  }
  
  .project-image {
    width: 100%;
    height: 200px; /* Fixed height for images */
    object-fit: cover; /* Ensures the image covers the area nicely */
  }
  
  
  
  .project-summary {
    margin-bottom: 10px; /* Space between summary and meta info */
    padding-left: 15px;
  }
  
  
  