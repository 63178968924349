.hero {
    display: flex;
    align-items: center;
    justify-content: start;
  
    color: #ffffff; /* Light text */
    padding-left: 12rem;
    padding-right: 12rem;
    padding-top: 5rem;
    padding-bottom: 2rem;
    gap: 2rem;

  }
  
  .profile-image {
    border-radius: 50%; /* Makes the image round */
    max-width: 250px;
    width: auto; /* Adjust size as needed */
    height: auto; /* Adjust size as needed */
    object-fit: cover; /* Ensures the image fits well */
  }
  
  .hero-text {
    max-width: 800px;
    width: 75%;

  }
  .hero-image{
    width: 25%;
  }
  